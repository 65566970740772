<template>
  <v-container fluid class="pt-0">
    <v-row dense>
      <v-col v-if="program.html" cols="12">
        <div v-html="program.html"></div>
      </v-col>
      <v-col
        cols="12"
        :sm="levels.length > 1 ? 6 : 12"
        md="6"
        :offset-md="levels.length === 1 ? 3 : null"
        :xl="levels.length > 2 ? 3 : 6"
        v-for="level in levels"
        :key="level.id"
      >
        <v-hover>
          <div slot-scope="{ hover }" class="fill-height">
            <level-card
              :level="level"
              :actionText="'Join Now'"
              :hover="hover"
              @selected="selectLevel"
            ></level-card>
          </div>
        </v-hover>
      </v-col>
    </v-row>
    <v-row dense align="baseline">
      <v-col cols="12" sm="6" class="headline text-center text-sm-right fill-height" >
        Already a member?
      </v-col>
      <v-col cols="12" sm="6" class="headline text-center text-sm-left">
        <span class="clickable">Find your number here</span>
        <v-btn color="color3 color3Text--text" small fab :to="{name: 'member-search'}" class="ml-2">
          <v-icon small>fas fa-search</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-toolbar :color="`${white}--text`" flat style="background: none;">
          <v-toolbar-title>{{selectedLevel && selectedLevel.title && selectedLevel.title.replace('Membership', '')}} Membership</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn :color="'color3'" icon text small @click.stop="dialog = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-2 pt-0">
          <v-list two-line v-if="profile && window !== 5">
            <search-result-item
              :player="profile"
              :showId="false"
            ></search-result-item>
            <v-slide-x-reverse-transition mode="out-in">
              <club-search-result-item
                v-if="selectedClub && window === 4"
                :team="selectedClub"
                :showId="false"
              ></club-search-result-item>
            </v-slide-x-reverse-transition>
          </v-list>
          <v-alert type="info" v-if="avpInfo && avpInfo.message">{{ avpInfo.message }}</v-alert>
          <v-window v-model="window" touchless>
            <!-- Player Lookup -->
            <v-window-item :value="0">
              <v-container fluid>
                <v-row dense>
                  <v-col cols="12">
                    <v-expand-transition>
                      <div v-if="avpCheck" class="text-center">
                        <div>Checking your AVP status...</div>
                        <v-progress-circular indeterminate size="32" color="success"></v-progress-circular>
                      </div>
                    </v-expand-transition>
                  </v-col>
                  <v-col v-if="!avpCheck" cols="12" class="text-h5 text-center" :class="`${white}--text`">
                    First, let's find your player profile.
                  </v-col>
                  <v-col v-if="!avpCheck && window === 0" cols="12">
                    <player-lookup
                      @player-selected="onProfileSelected"
                      :clearOnSelect="true"
                      ref="search"
                      :allowAdd="true"
                      @manual-click="onManualClick"
                      :asOf="asOf"
                      @searched-change="onSearched"
                    ></player-lookup>
                  </v-col>
                  <v-col class="text-center">
                    <v-expand-transtion>
                      <div v-if="searched" class="title">
                        Can't find the player?
                        <v-btn color="color3" text @click="onManualClick">Manually enter the player</v-btn>
                      </div>
                    </v-expand-transtion>
                  </v-col>
                </v-row>
              </v-container>
            </v-window-item>
            <!-- Avp -->
            <v-window-item :value="1">
              <v-slide-x-reverse-transition  mode="out-in" leave-absolute>
                <div v-if="!askAvp && !newAvp">
                  <div class="text-center title">Do you have an AVP Number?</div>
                  <div class="text-center">
                    <v-btn color="success" class="mr-3" @click="askAvp = true">Yes</v-btn>
                    <v-btn color="error" @click="newMember(selectedLevel)">No</v-btn>
                  </div>
                </div>
                <div v-else-if="newAvp">
                  <person-form
                    v-if="fields"
                    ref="p1"
                    :player="player"
                    @valid-change="onValidChange"
                    :fields="['gender', ...fields.top.ask, ...fields.bottom.ask]"
                    :requiredFields="[...fields.top.req, ...fields.bottom.req]"
                    :allowInternational="true"
                    :embed="true"
                  ></person-form>
                </div>
                <div v-else class="px-3 text-center">
                  <v-text-field
                    label="Avp Number"
                    v-model="numToValidate"
                    solo-inverted
                    autofocus
                    type="search"
                    autocomplete="off"
                    :hide-details="!validationError"
                    :loading="loading"
                    :error-messages="loading ? [] : [validationError]"
                  ></v-text-field>
                  <v-btn
                    color="success"
                    class="mt-2"
                    :disabled="!numToValidate"
                    :loading="loading"
                    @click="validateAVP"
                  >Validate</v-btn>
                </div>
              </v-slide-x-reverse-transition>
            </v-window-item>
            <v-window-item :value="2">
              <v-row dense v-if="validationResult" class="text-center">
                <v-col cols="12" class="subheading">
                  {{numToValidate}} is a valid AVP America Number belonging to
                  {{validationResult.firstName}} {{validationResult.lastName}}
                </v-col>
                <v-col cols="12" class="pt-4">
                  <v-btn
                    color="success"
                    @click.stop="saveNumber"
                    :loading="loading"
                  >That's me... Save it</v-btn>
                </v-col>
              </v-row>
            </v-window-item>
            <!-- Confirm Fields -->
            <v-window-item :value="3">
              <v-container>
                <v-row dense>
                  <v-col cols="12" v-if="player">
                    <ClubAutocomplete
                      v-if="!addClub"
                      label="Club*"
                      v-model="player.club"
                      :allowAdd="true"
                      @manual-click="(v) => {player.club = v; addClub = true}"
                      @selected="(v) => {selectedClub = v}"
                    ></ClubAutocomplete>

                    <v-text-field
                      v-if="addClub"
                      name="club"
                      label="Club*"
                      v-model="player.club"
                      :hint="`What club do you play for?`"
                      persistent-hint
                      color="color3"
                      autofocus
                    >
                      <template v-slot:append-outer>
                        <v-btn text icon @click.stop="addClub = false">
                          <v-icon>fas fa-search</v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-window-item>
            <!-- Confirm -->
            <v-window-item :value="4">
              <v-container class="pa-0">
                <v-row dense>
                  <v-col cols="12">
                    <table :class="`confirm ${white}--text`">
                      <tbody>
                        <tr>
                          <th>Membership:</th>
                          <td>{{selectedLevel && selectedLevel.title}}</td>
                        </tr>
                        <tr>
                          <th>Price:</th>
                          <td>{{selectedLevel && selectedLevel.price | usDollars}}</td>
                        </tr>
                        <tr v-if="jProps && jProps.avp">
                          <th></th>
                          <td>*Includes AVP Silver membership</td>
                        </tr>
                      </tbody>
                    </table>
                  </v-col>
                </v-row>
                <v-expand-transition>
                  <v-card v-if="waivers.length" class="pa-2" flat>
                    <v-row
                      dense
                      justify="end"
                      v-for="w in waivers"
                      :key="w.label"
                    >
                      <v-col cols="12">
                        <v-checkbox
                          v-model="agreed"
                          :value="w.url"
                          hide-details
                          color="success"
                          class="mt-0"
                        >
                          <template v-slot:label>
                            <div>I have read and agree to the&nbsp;
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <a
                                    target="_blank"
                                    :href="w.url"
                                    @click.stop
                                    v-on="on"
                                    class="color3--text" style="text-decoration: none"
                                  >
                                    {{w.label}}
                                  </a>
                                </template>
                                Opens in new window
                              </v-tooltip>
                            </div>
                          </template>
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expand-transition>
                <v-row>
                  <v-col cols="12" class="text-center" v-if="false">
                    <v-btn
                      v-if="window === 2"
                      color="color3Text color3--text"
                      @click.stop="addToCart()"
                      :disabled="!agreedToAll"
                    >
                      Add To Cart
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-window-item>
            <!-- Add Another? -->
            <v-window-item :value="5">
              <v-container>
                <v-row dense>
                  <v-col cols="12" :class="`headline ${white}--text`">
                    {{cartItem && cartItem.description}} has been added to the cart!
                  </v-col>
                </v-row>
                <v-row justify="center" class="pt-4">
                  <v-btn :block="$vuetify.breakpoint.xsOnly" class="mr-2" color="color3 color3Text--text" :to="{name: 'checkout'}">Check Out Now</v-btn>
                  <v-btn :block="$vuetify.breakpoint.xsOnly" :class="{'mt-2': $vuetify.breakpoint.xsOnly}" color="color3 color3Text--text" @click.stop="window=0">Add Another</v-btn>
                </v-row>
              </v-container>
            </v-window-item>
          </v-window>
        </v-card-text>
        <v-card-actions style="min-height:56px">
          <v-fab-transition>
            <v-btn
              v-if="window && window !== 4"
              color="color3 color3Text--text"
              fab small
              @click.stop="window = 0"
            >
              <v-icon>fas fa-caret-left</v-icon>
            </v-btn>
          </v-fab-transition>
          <v-spacer></v-spacer>
          <v-fab-transition>
            <v-btn
              v-if="(window === 1 && newAvp) || this.window === 3"
              color="success white--text"
              @click.stop="window = 4"
              :disabled="(window === 1 && !validPlayer) || (window === 3 && !player.club)"
            >
              continue <v-icon class="ml-2">fas fa-caret-right</v-icon>
            </v-btn>
          </v-fab-transition>
          <v-fab-transition>
            <v-btn
              v-if="window === 4"
              color="color3 color3Text--text"
              text
              @click.stop="addToCart()"
              :disabled="!agreedToAll"
            >
              Add To Cart
            </v-btn>
          </v-fab-transition>
          <v-fab-transition>
            <v-btn
              v-if="window === 4"
              color="color3 color3Text--text"
              text small
              @click.stop="addToCart()"
              :disabled="!agreedToAll"
            >
              <v-icon small>fas fa-shopping-cart</v-icon>
            </v-btn>
          </v-fab-transition>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import dark from '@/classes/IsDark'
import LevelCard from '@/components/Membership/LevelCard'
import { mapGetters } from 'vuex'
import SearchResultItem from '@/components/Player/Search/SearchResultItem'
import PlayerLookup from '@/components/Player/Search/PlayerLookup'
import CartItem from '@/classes/CartItem'
import * as actions from '@/store/ActionTypes'
import PersonForm from '@/components/Forms/PersonForm'
import PlayerProfile from '../../classes/PlayerProfile'
import ClubAutocomplete from '@/components/Clubs/ClubAutocomplete.vue'
import ClubSearchResultItem from '@/components/Clubs/SearchResultItem.vue'

export default {
  props: ['program'],
  data () {
    return {
      selectedLevel: null,
      profile: null,
      dialog: false,
      window: 0,
      instructions: null,
      agreed: [],
      avpCheck: false,
      avpInfo: null,
      askAvp: false,
      numToValidate: null,
      validationResult: null,
      validationError: null,
      loading: false,
      previousWindow: -1,
      newAvp: false,
      player: null,
      validPlayer: false,
      searched: false,
      addClub: false,
      selectedClub: null
    }
  },
  computed: {
    ...mapGetters(['user', 'color1IsDark']),
    white () {
      return this.color1IsDark ? 'color1' : 'color1Text'
    },
    oName () {
      return this.program.organization.name
    },
    cartItem () {
      if (!(this.selectedLevel && this.profile)) return null
      const item = new CartItem()
      item.newMembership2(this.program, this.selectedLevel, this.player, null, this.avpInfo)

      return item
    },
    jProps () {
      return this.selectedLevel && this.selectedLevel.jsonProps && JSON.parse(this.selectedLevel.jsonProps)
    },
    fields () {
      return this.jProps && this.jProps.fields
    },
    asOf () {
      return this.jProps && this.jProps.avp ? this.jProps.avp.asOf : null
    },
    waivers () {
      return (this.jProps && this.jProps.waivers) || []
    },
    agreedToAll () {
      const a = this.agreed
      a.sort()
      return !this.waivers || JSON.stringify(a) === JSON.stringify(this.waivers.map(m => m.url).sort())
    },
    pid () {
      return +this.$route.query.pid
    },
    levels () {
      if (this.pid) {
        var l = this.program.levels.find(f => f.id === this.pid)
        if (l) {
          this.selectLevel(l)
          return [l]
        }
      }
      return this.program.levels
    }
  },
  methods: {
    onSearched (v) {
      if (v) {
        this.searched = true
      }
    },
    onManualClick () {
      if (this.jProps && this.jProps.avp) {
        this.window = 1
      } else {
        this.newMember(this.selectedLevel)
      }
    },
    onValidChange (v) {
      this.validPlayer = v
    },
    selectLevel (level) {
      if (this.user && this.user.memberRoute) {
        this.$route.push(this.user.memberRoute)
        return
      }

      this.selectedLevel = level
      this.window = 0
      this.dialog = true
    },
    playerFromProfile (profile) {
      if (this.profile) {
        this.player = new PlayerProfile(null, {
          firstName: this.profile.firstName,
          lastName: this.profile.lastName,
          id: this.profile.id,
          playerProfileId: this.profile.id,
          male: this.profile.male
        })
      }
    },
    onProfileSelected (profile) {
      this.profile = profile
      this.playerFromProfile(profile)
      if (this.jProps && this.jProps.avp) {
        this.checkAvpMembership(profile.id)
      } else if (this.jProps && this.jProps.confirmClub) {
        this.window = 3
      } else {
        this.window = 4
      }
    },
    newMember (level) {
      if (this.profile) {
        this.newAvp = true
        this.askAvp = false
        return
      }

      this.$emit('level-selected', level)
      this.$router.push({ name: 'new-member', params: { level: level } })
    },
    hasNumber (yes) {
      this.instructions = yes ? `Enter your ${this.oName} number, name or email` : null
      this.window = 1
    },
    isDark (color) {
      return dark(color)
    },
    addToCart (ci) {
      var cartItem = ci || this.cartItem
      console.log('here')
      if (cartItem) {
        this.$store.dispatch(actions.ADD_CART_ITEM, cartItem)
        if (this.jProps && this.jProps.avp) {
          this.$router.push({ name: 'checkout' })
          return
        }
        this.window = 5
      }
    },
    checkAvpMembership (id) {
      this.avpCheck = true
      this.$VBL.getRumble(id)
        .then(r => {
          this.processAvp(r.data)
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.avpCheck = false
        })
    },
    validateAVP () {
      const n = this.numToValidate
      if (!n) return
      this.loading = true
      this.$VBL.verifyAVPA(n, this.asOf)
        .then(r => {
          this.validationResult = r.data
          if (r.data.status === 'false') {
            this.validationError = `${n} is not a valid AVP Number`
            return
          }
          if (r.data.profile) {
            console.log(r.data.profile)
            this.window = 0
            this.$nextTick(() => {
              this.onProfileSelected(r.data.profile)
            })
          }
          this.$nextTick(() => {
            this.window = 2
          })
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    saveNumber () {
      if (!(this.profile && this.numToValidate)) return this.createProfileFromAvp()
      const dto = {
        id: this.profile.id,
        avpAmericaNumber: this.numToValidate
      }
      this.loading = true
      this.$VBL.player.patchNumber(dto)
        .then(r => {
          this.numToValidate = null
          const id = this.profile.id
          // this.window = 0
          this.checkAvpMembership(id)
        })
        .catch(e => { console.log(e.response) })
        .finally(() => { this.loading = false })
    },
    createProfileFromAvp () {
      this.loading = true
      this.$VBL.verifyAVPA(this.numToValidate, null, true)
        .then(r => {
          this.numToValidate = null
          this.window = 0
          this.$nextTick(() => {
            this.onProfileSelected(r.data.profile)
          })
        })
        .catch(e => { console.log(e.response) })
        .finally(() => { this.loading = false })
    },
    processAvp (dto) {
      this.avpInfo = dto
      if (dto.createNew) {
        this.window = 1
      } else {
        this.window = 4
      }
    },
    reset () {
      this.avpInfo = null
      this.profile = null
      this.numToValidate = null
      this.askAvp = false
      this.avpCheck = false
      this.avpInfo = null
      this.numToValidate = null
      this.validationResult = null
      this.validationError = null
      this.newAvp = false
      this.agreed = []
      this.selectedClub = null
    }
  },
  watch: {
    dialog: function (val) {
      if (!val) {
        this.window = 0
        this.reset()
      }
    },
    'user.memberRoute': function (val) {
      if (val) {
        val.query = { levels: true }
        this.$router.push(val)
      }
    },
    window: function (n, o) {
      if (n > o) this.previousWindow = o
      if (n === 0) {
        this.reset()
      } else {
        this.$refs.search && this.$refs.search.blur()
      }
      if (n === 1) this.agreed = []
    }
  },
  created () {
    // const pid = this.$route.query.pid
    const p = this.$route.params.profile
    const l = this.$route.params.level
    const s = this.$route.params.step
    if (p && l) {
      this.selectedLevel = l
      this.profile = p
      if (s === 'addToCart') {
        const item = new CartItem()
        item.newMembership(this.program, this.selectedLevel, p, null, this.avpInfo)
        this.addToCart(item)
        this.$router.push({ name: 'checkout' })
      } else {
        this.window = 2
      }
      this.dialog = true
    }
  },
  components: {
    LevelCard,
    SearchResultItem,
    PlayerLookup,
    PersonForm,
    ClubAutocomplete,
    ClubSearchResultItem
  }
}
</script>

<style scoped>
.confirm th {
  text-align: right
}
</style>
